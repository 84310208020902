<template>
  <div>业务全景</div>
</template>

<script>
export default {
  name: 'opportunity'
}
</script>

<style lang="less" scoped></style>
